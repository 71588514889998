import { Controller, useFormContext } from "react-hook-form";
import { Select } from "../../components/Select";
import { Textarea } from "../../components/Textarea";
import { BonusBanner } from "../../components/BonusBanner";
import type { SurveyFormVm } from "./SurveyForm";
import { ErrorMessage } from "@hookform/error-message";
import { LaiErrorMessage } from "../../components/ErrorMessage";
import { useEffect, useState, useMemo, useCallback } from "react";
import { GroupedCheckboxes } from "../../components/GroupedCheckboxes";
import {
  recommendationTypeOptions,
  recommendationDefaultOption,
  recommendationTypeValueFormatter,
  getRecommendationOptions,
  RecommendationType,
} from "../../models/opportunity";
import { SystemTypeOption } from "../../api/interface";
import { RadioButtons } from "../../components/RadioButtons";
import { priorityOptions } from "../../models/opportunity";
import { TextField } from "../../components/TextField";
import { RadioGroup } from "../../components/RadioGroup";
import { yesNoOptions } from "../../models/elevator";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";

const RecommendationsTab = () => {
  const {
    control,
    formState: { errors },
    setValue,
    clearErrors,
    watch,
  } = useFormContext<SurveyFormVm>();
  const [recommendationType, setRecommendationType] =
    useState<RecommendationType>(recommendationDefaultOption);
  const [isPartsOpen, setIsPartsOpen] = useState<boolean>(true);

  const selectedRecommendationOptions = watch(
    "recommendation.recommendationOptions",
  );
  // const recommendations = watch("recommendation");
  const parts = watch("recommendation.parts");

  useEffect(() => {
    if (recommendationType === recommendationDefaultOption) {
      setValue("recommendation", null);
      clearErrors("recommendation");
    } else {
      setValue("recommendation", {
        type: recommendationType,
        opportunity: "",
        recommendationOptions: [],
        priority: undefined,
        billableExplanation: null,
        manhours: null,
        isThereAMechanicTeam: null,
        parts: [
          {
            partNumber: "",
            partQuantity: null,
            partDescription: "",
            partPrice: null,
          },
        ],
      });
    }
  }, [recommendationType, setValue, clearErrors]);

  const systemType = watch("systemType");

  const recommendationOptions = useMemo(() => {
    return getRecommendationOptions(systemType as SystemTypeOption);
  }, [systemType]);

  const addPart = useCallback(() => {
    setValue("recommendation.parts", [
      ...(parts ?? []),
      {
        partNumber: "",
        partQuantity: null,
        partDescription: "",
        partPrice: null,
      },
    ]);
  }, [parts, setValue]);

  return (
    <div className="grid grid-cols-1 gap-4 mb-10">
      <BonusBanner />
      <Select
        name="recommendation.recommendationType"
        label="Recommendation Type"
        value={recommendationType}
        options={recommendationTypeOptions}
        valueFormatter={recommendationTypeValueFormatter}
        onChange={(value) => {
          if (typeof value !== "string") {
            // This should not happen but we need to appease typescript
            return;
          }
          setRecommendationType(value);
        }}
      />
      {recommendationType === recommendationTypeOptions[1] ? (
        <Controller
          control={control}
          name="recommendation.recommendationOptions"
          render={({ field: { ref: _, onChange, ...fieldProps } }) => (
            <GroupedCheckboxes
              options={recommendationOptions}
              label="Recommendation Options"
              selectedOptions={selectedRecommendationOptions || []}
              onCheck={(newValue) => onChange(newValue)}
              {...fieldProps}
            />
          )}
        />
      ) : null}
      <Controller
        control={control}
        rules={{ required: true }}
        name="recommendation.priority"
        render={({ field: { ref: _, onChange, ...fieldProps } }) => (
          <RadioButtons
            options={priorityOptions}
            label="Priority"
            onChange={(newValue) => onChange(newValue)}
            {...fieldProps}
          />
        )}
      />
      {recommendationType !== recommendationDefaultOption ? (
        <Controller
          control={control}
          name="recommendation.opportunity"
          render={({ field: { ref: _, ...fieldProps } }) => (
            <Textarea
              helperText="Please add any helpful information about the building, parking, access to building, where to find building contact, access to unit, keys needed, machine room location and access, etc."
              label="Recommendation Description"
              disabled={false}
              {...fieldProps}
            />
          )}
        />
      ) : null}
      <Controller
        control={control}
        name="recommendation.billableExplanation"
        render={({ field: { ref: _, ...fieldProps } }) => (
          <Textarea
            label="If Billable, explain why and cause:"
            {...fieldProps}
          />
        )}
      />
      <Controller
        control={control}
        name="recommendation.manhours"
        render={({ field: { ref: _, ...fieldProps } }) => (
          <TextField
            type="number"
            label="Total Man Hours including travel and supply run:"
            {...fieldProps}
          />
        )}
      />
      <Controller
        control={control}
        name="recommendation.isThereAMechanicTeam"
        render={({ field: { ref: _, ...fieldProps } }) => (
          <RadioGroup
            label="Is there a mechanic team?"
            options={yesNoOptions}
            {...fieldProps}
          />
        )}
      />
      <div
        className="flex justify-between items-center bg-main-light p-2 rounded-md cursor-pointer"
        onClick={() => setIsPartsOpen(!isPartsOpen)}
      >
        <span className="font-semibold">Parts</span>
        {isPartsOpen ? (
          <ChevronUpIcon className="w-5 h-5" />
        ) : (
          <ChevronDownIcon className="w-5 h-5" />
        )}
      </div>
      {isPartsOpen && (
        <>
          {parts?.map((part, index) => (
            <div
              key={part.partNumber + index}
              className="flex flex-col gap-2 border-b border-main-light pb-5"
            >
              <Controller
                control={control}
                name={`recommendation.parts.${index}.partNumber`}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <TextField type="text" label="Part #" {...fieldProps} />
                )}
              />
              <Controller
                control={control}
                name={`recommendation.parts.${index}.partQuantity`}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <TextField type="number" label="Quantity" {...fieldProps} />
                )}
              />
              <Controller
                control={control}
                name={`recommendation.parts.${index}.partDescription`}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <TextField
                    type="text"
                    label="Part Description"
                    {...fieldProps}
                  />
                )}
              />
              <Controller
                control={control}
                name={`recommendation.parts.${index}.partPrice`}
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <TextField type="number" label="Price" {...fieldProps} />
                )}
              />
            </div>
          ))}
          <div
            className="flex justify-start gap-2 items-center bg-main-light p-2 rounded-md cursor-pointer"
            onClick={addPart}
          >
            <PlusIcon className="w-5 h-5 text-main" />
            <span className="text-main">Add Part</span>
          </div>
        </>
      )}

      <ErrorMessage
        as={LaiErrorMessage}
        errors={errors}
        name="recommendation.opportunity"
      />
    </div>
  );
};

export default RecommendationsTab;
