import { SystemType, type SystemTypeOption } from "../api/interface";
import { z } from "zod";
import { isHydraulic, isTraction } from "./elevator";

// Common Opportunity Types
export const opportunityTypeMap = new Map([
  ["repair-recommendation", "Recommendation"],
  ["modernization", "Modernization"],
  ["cat-1-test", "CAT 1 Test"],
  ["cat-5-test", "CAT 5 Test"],
  ["contract", "Contract"],
  ["code-change", "Code Change"],
  ["data-driven", "Data Driven"],
]);

const opportunityType = z.enum([
  "repair-recommendation",
  "modernization",
  "cat-1-test",
  "cat-5-test",
  "contract",
  "code-change",
  "data-driven",
]);
export const opportunityTypeOptions = opportunityType.options;
export type OpportunityType = z.infer<typeof opportunityType>;

const opportunityStatus = z.enum([
  "office-reviewed",
  "office-approved",
  "office-rejected",
  "quote-sent-to-operations",
  "quote-sent-to-customer",
]);
export const opportunityStatusOptions = opportunityStatus.options;
export type OpportunityStatus = z.infer<typeof opportunityStatus>;

export const getRecommendationStatusLabel = (status?: OpportunityStatus) => {
  switch (status) {
    case "quote-sent-to-operations":
    case "office-reviewed":
    case "office-approved":
    case "office-rejected":
      return "Quote Sent to Operations";
    case "quote-sent-to-customer":
      return "Quote Sent to Customer";
    default:
      return "Sent";
  }
};

const commonOpportunitySchema = z.object({
  id: z.string(),
  type: opportunityType,
  source: z.string().optional().describe("Source of the recommendation"),
  customerId: z.string().optional(),
  customerName: z.string().optional(),
  branch: z.string().optional(),
  site: z.string().describe("Site ID"),
  siteName: z.string().optional().describe("Site name"),
  customer: z.string().optional(),
  unit: z.string().describe("Unit (i.e.: elevator) ID"),
  unitName: z.string().describe("Unit name"),
  value: z
    .string()
    .optional()
    .describe(
      "Estimated value of the recommendation: low, medium, high, or a specific dollar amount",
    ),
  createdAt: z.string().describe("Date the recommendation was created"),
  status: opportunityStatus.optional(),
  archived: z.boolean().optional(),
});

// Repair Recommendation Types
const recommendationType = z.enum([
  "none",
  "repair-recommendation",
  "modernization",
  "cat-1-test",
  "cat-5-test",
  "contract",
]);
export const recommendationTypeOptions = recommendationType.options;
export const recommendationDefaultOption = recommendationType.Enum.none;
export type RecommendationType = z.infer<typeof recommendationType>;
const recommendationLabelMap: { [key in RecommendationType]: string } = {
  [recommendationType.Enum.none]: "None",
  [recommendationType.Enum["repair-recommendation"]]: "Repair / Upgrade",
  [recommendationType.Enum.modernization]: "Modernization",
  [recommendationType.Enum["cat-1-test"]]: "CAT 1 Test",
  [recommendationType.Enum["cat-5-test"]]: "CAT 5 Test",
  [recommendationType.Enum.contract]: "Contract",
};
export const recommendationTypeValueFormatter = (
  value: string | null,
): string => {
  if (recommendationLabelMap[value as RecommendationType]) {
    return recommendationLabelMap[value as RecommendationType];
  }

  throw new Error(
    `Invalid recommendation type: ${value}. Value must be one of: ${recommendationTypeOptions.join(", ")}`,
  );
};

export const priorityOptions = [
  { id: "critical", label: "Critical", helperText: "Unit is shutdown" },
  {
    id: "urgent",
    label: "Urgent",
    helperText: "Required repair for impending issue",
  },
  {
    id: "recommended",
    label: "Recommended",
    helperText: "No impact on current operation",
  },
];

const recommendationOptions = {
  HydroTraction: [
    "DLM - Door Lock Monitoring",
    "3D Sensor Edge",
    "Car Top Handrails",
    "Slide guides / Roller Guides",
    "Hospital - Enforcer Door Gibs",
    "SmarTork Spirators (Facility with air drafts)",
  ],
  Hydro: [
    "Soft Start (Electronic Starter)",
    "Pump Unit Upgrade",
    "Valve Upgrade",
    "Tank Cooler or Tank Heater needed",
    "Selector Upgrade (VMI 3.14 or Maxton Electronic)",
    "Jack - Single Bottom Bulkhead",
    "Machine Sheave",
    "Brake",
    "Governor",
    "Selector Upgrade patch on older systems",
  ],
  TractionGeared: ["Rope Gripper", "Hoist Ropes"],
};

export const getRecommendationOptions = (systemType: SystemTypeOption) => {
  const options = [];
  if (isTraction(systemType)) {
    options.push(...recommendationOptions.HydroTraction);
    if (systemType === SystemType.TRACTION_GEARED) {
      options.push(...recommendationOptions.TractionGeared);
    }
  } else if (isHydraulic(systemType)) {
    options.push(...recommendationOptions.HydroTraction);
    options.push(...recommendationOptions.Hydro);
  } else {
    options.push(...recommendationOptions.HydroTraction);
    options.push(...recommendationOptions.TractionGeared);
    options.push(...recommendationOptions.Hydro);
  }

  return options;
};

export const repairRecommendationSchema = commonOpportunitySchema.extend({
  type: z.literal(opportunityType.Enum["repair-recommendation"]),
  opportunity: z.string(),
  description: z.string().optional(),
  fromId: z
    .string()
    .describe("User ID of the person who created the recommendation")
    .optional(),
  fromEmail: z
    .string()
    .describe("Email of the person who created the recommendation")
    .optional(),
  recommendationOptions: z.array(z.string()).optional(),
  priority: z.enum(["critical", "urgent", "recommended"]).optional(),
  billableExplanation: z.string().nullable().optional(),
  manhours: z.coerce.number().int().nullable().optional(),
  isThereAMechanicTeam: z.boolean().nullable().optional(),
  parts: z
    .array(
      z.object({
        partNumber: z.string().nullable().optional(),
        partQuantity: z.coerce.number().int().nullable().optional(),
        partDescription: z.string().nullable().optional(),
        partPrice: z.coerce.number().int().nullable().optional(),
      }),
    )
    .nullable()
    .optional(),
});
export const repairRecommendationSchemaWithoutId =
  repairRecommendationSchema.omit({
    id: true,
  });

export const modernizationSchema = repairRecommendationSchema
  .omit({ type: true })
  .extend({
    type: z.literal(opportunityType.Enum.modernization),
  });

export const modernizationSchemaWithoutId = modernizationSchema.omit({
  id: true,
});

export const cat1TestSchema = repairRecommendationSchema
  .omit({ type: true })
  .extend({
    type: z.literal(opportunityType.Enum["cat-1-test"]),
  });

export const cat1TestSchemaWithoutId = cat1TestSchema.omit({
  id: true,
});

export const cat5TestSchema = repairRecommendationSchema
  .omit({ type: true })
  .extend({
    type: z.literal(opportunityType.Enum["cat-5-test"]),
  });

export const cat5TestSchemaWithoutId = cat5TestSchema.omit({
  id: true,
});

export type Cat1Test = z.infer<typeof cat1TestSchema>;
export type Cat5Test = z.infer<typeof cat5TestSchema>;
export type Modernization = z.infer<typeof modernizationSchema>;
export type Recommendation = z.infer<typeof repairRecommendationSchema>;
export type RecommendationWithoutId = z.infer<
  typeof repairRecommendationSchemaWithoutId
>;

// Contract Types
export const contractSchema = commonOpportunitySchema.extend({
  type: z.literal(opportunityType.Enum.contract),
  contractId: z.string(),
  opportunity: z.string(),
  fromId: z.string().optional(),
  fromEmail: z.string().optional(),
});
export type Contract = z.infer<typeof contractSchema>;
export const contractSchemaWithoutId = contractSchema.omit({
  id: true,
});
export type ContractWithoutId = z.infer<typeof contractSchemaWithoutId>;

// Code Change Types
export const codeChangeSchema = commonOpportunitySchema.extend({
  type: z.literal(opportunityType.Enum["code-change"]),
});
export type CodeChange = z.infer<typeof codeChangeSchema>;
export const codeChangeSchemaWithoutId = codeChangeSchema.omit({
  id: true,
});
export type CodeChangeWithoutId = z.infer<typeof codeChangeSchemaWithoutId>;

// Data-Driven Types
export const dataDrivenSchema = commonOpportunitySchema.extend({
  type: z.literal(opportunityType.Enum["data-driven"]),
  column: z.string().optional(),
  opportunity: z.string(),
});
export type DataDriven = z.infer<typeof dataDrivenSchema>;
export const dataDrivenSchemaWithoutId = dataDrivenSchema.omit({
  id: true,
});
export type DataDrivenWithoutId = z.infer<typeof dataDrivenSchemaWithoutId>;

export const opportunitySchema = z.discriminatedUnion("type", [
  repairRecommendationSchema,
  modernizationSchema,
  cat1TestSchema,
  cat5TestSchema,
  contractSchema,
  codeChangeSchema,
  dataDrivenSchema,
]);
export type Opportunity = z.infer<typeof opportunitySchema>;
export const opportunitySchemaWithoutId = z.discriminatedUnion("type", [
  repairRecommendationSchemaWithoutId,
  modernizationSchemaWithoutId,
  cat1TestSchemaWithoutId,
  cat5TestSchemaWithoutId,
  contractSchemaWithoutId,
  codeChangeSchemaWithoutId,
  dataDrivenSchemaWithoutId,
]);
export type OpportunityWithoutId = z.infer<typeof opportunitySchemaWithoutId>;
