import { z } from "zod";
import { Customer } from "../models/customer";
import {
  Elevator,
  ElevatorInput,
  ElevatorImageFields,
} from "../models/elevator";
import { Property } from "../models/property";
import {
  Opportunity,
  OpportunityWithoutId,
  RecommendationWithoutId,
} from "../models/opportunity";
import { MCPCategoriesWithTasks, MCPEntry } from "../models/mcp";
import { ContractOptions } from "../models/contract-options";
import { User } from "../models/user";
import {
  ContractEstimateOpts,
  SendEmailOpts,
  HoursPerMonthPerUnitAll,
  FMAllVisits,
  GetSmartFiltersRequest,
  GetSmartFiltersResponse,
  Filter,
  SystemType,
  HydroSystemType,
  TractionSystemType,
  SystemTypeOption,
  QuerySalesGenieDbInput,
  QuerySalesGenieDbResponse,
} from "@functions";
import { Region } from "../models/contractInputs";
import { CopilotElevator, CopilotProperty } from "../models/copilot";

export type {
  ContractEstimateOpts,
  SendEmailOpts,
  FMAllVisits,
  GetSmartFiltersRequest,
  GetSmartFiltersResponse,
  Filter,
  SystemTypeOption,
};

export { SystemType, HydroSystemType, TractionSystemType };

export type WithoutId<T> = Omit<T, "id">;

export const FieldOptionValues = [
  "typeOfUse",
  "ahj",
  "facilityType",
  "doorType",
  "technology",
  "sensorEdgeBrandAndModel",
  "motorControllerType",
  "governerType",
  "brakeType",
  "controllerManufacturer",
  "controllerModel",
  "controllerValveManufacturer",
  "controllerValveModel",
  "doorRestrictorsType",
  "guideType",
  "doorOperatorModel",
  "bufferType",
  "systemType",
] as const;

export const FieldOptionKey = z.enum(FieldOptionValues);

export type FieldOptionKey = z.infer<typeof FieldOptionKey>;

export type OptionsWithCustomInputSelect = keyof Pick<
  Elevator,
  | "typeOfUse"
  | "ahj"
  | "facilityType"
  | "doorType"
  | "technology"
  | "sensorEdgeBrandAndModel"
  | "motorControllerType"
  | "governerType"
  | "brakeType"
  | "controllerManufacturer"
  | "controllerModel"
  | "controllerValveManufacturer"
  | "controllerValveModel"
  | "doorRestrictorsType"
  | "guideType"
  | "doorOperatorModel"
  | "bufferType"
  | "systemType"
>;

export type GetPropertiesOpts = {
  customerId: string;
};

export type GetElevatorsForPropertyOpts = {
  propertyId: string;
};

export type GetElevatorsForPropertiesOpts = {
  propertyIds: string[];
};

export type SaveElevatorOpts = {
  elevator: ElevatorInput;
};

export type UpdateElevatorOpts = {
  id: string;
  updates: Partial<Omit<Elevator, "id">>;
};

export type SaveContractOpts = {
  contract: WithoutId<ContractOptions>;
};

export type CreateCustomerOpts = {
  customer: WithoutId<Customer>;
};

export type CreatePropertyOpts = {
  property: WithoutId<Property>;
};

export type AddMCPEntryOpts = {
  propertyId: string;
  elevatorId: string;
  surveyId: string;
  tasksCompleted: string[];
  totalTasks: number;
  siteName: string;
  unitName: string;
};

export type AddElevatorForPropertyOpts = {
  elevator: WithoutId<Elevator>;
};

export type AddRecommendationForElevatorOpts = {
  recommendation: RecommendationWithoutId;
};

export type AddOpportunityOpts = {
  opportunity: OpportunityWithoutId;
  isSurvey?: boolean;
};

export type GetOpportunitiesOpts = {
  mechanicId?: string;
};

export type GetOptionsForFieldOpts = {
  fieldName: FieldOptionKey;
};

export type AddOptionForFieldOpts = {
  fieldName: FieldOptionKey;
  value: string;
};

export type LoginOpts = {
  email: string;
  password: string;
};

export type SendPasswordResetEmailOpts = {
  email: string;
};

export type AddPhotoForElevatorOpts = {
  elevatorId: string;
  photo: File;
  fieldName: keyof ElevatorImageFields;
};

export type ElevatorToEvaluate = {
  id: string;
  driveType: string;
  condition: string;
  stops: number;
};

export enum ContractOptionType {
  FM_SELECT = "Full Maintenance Select",
  FM_PREFERRED = "Full Maintenance Preferred",
  FM_PREMIER = "Full Maintenance Premier",
  E_L_SELECT = "E&L Select",
  E_L_PREFERRED = "E&L Preferred",
}

export const ContractOptionTypeLabel = {
  [ContractOptionType.FM_SELECT]: "Select",
  [ContractOptionType.FM_PREFERRED]: "Preferred",
  [ContractOptionType.FM_PREMIER]: "Premier",
  [ContractOptionType.E_L_SELECT]: "E&L Select",
  [ContractOptionType.E_L_PREFERRED]: "E&L Preferred",
};

export const RiskLevel = {
  NONE: "None",
  EXTREMELY_LOW: "Extremely Low",
  LOW: "Low",
  MEDIUM: "Medium",
  HIGH: "High",
  EXTREMELY_HIGH: "Extremely High",
} as const;
export type RiskLevel = (typeof RiskLevel)[keyof typeof RiskLevel];

export type contractOption = {
  totalContractPrice: number;
  contractCost: number;
  type: ContractOptionType;
  hoursPerYear: number;
  regionBurdenRate: number;
  grossProfitGoal: number;
  laborSubtotal: number;
  callbacksCostPerYear: number;
  travelTimeCostPerYear: number;
  materialsCost: number;
  intelevatorTotalMonthlyCost: number;
  intelevatorInitialCost: number;
  term: number;
  annualPrice: number;
  annualWithDiscount: number;
  totalTestingCost: number;
  monthlyPrice: number;
  quarterlyPrice: number;
  pricePerUnitPerMonth: number;
  monthlyRPH: number;
  includedTroubleCalls: number;
  elevators: visitPerElevator[];
  cat1Included: boolean;
  cat5Included: boolean;
};
export type visitPerElevator = {
  elevatorId: string;
  elevatorName?: string | null;
  hoursPerYear: number;
  hoursPerVisit: number;
  visitFrequency: string;
};
export type ContractEstimate = Record<ContractOptionType, contractOption>;

export type RegionBurdenRate = {
  [key in Region]: number;
};

export type OpportunityRiskLevel = {
  opportunity: Record<RiskLevel, number>;
  risk: Record<RiskLevel, number>;
};

export type ContractStandardsType = {
  regionBurdenRate: RegionBurdenRate;
  opportunityRiskLevels: OpportunityRiskLevel;
  hoursPerMonthPerUnit: HoursPerMonthPerUnitAll;
  planBenefitCost: {
    [key in ContractOptionType]: number;
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type GetCopilotPropertiesOpts = {};

export type GetCopilotElevatorsOpts = {
  userId: string | null;
};

export type ImportWorkdayOpts = {
  file: File;
  mechanicEmail: string;
};

export type ImportWorkdayResult = {
  successfulRows: number;
  failedRows: number;
  parseErrors: number;
};

export interface IAPI {
  login(opts: LoginOpts): Promise<User>;
  logout(): Promise<void>;
  requireUser(): Promise<User>;
  sendPasswordResetEmail(opts: SendPasswordResetEmailOpts): Promise<void>;
  generateDocumentId(): string;
  getCustomers(): Promise<Customer[]>;
  getCustomerById(id: string): Promise<Customer>;
  getCustomersByPropertyIds(
    propertyIds: string[],
  ): Promise<Map<string, Customer>>;
  getAllElevators(): Promise<Elevator[]>;
  createCustomer(opts: CreateCustomerOpts): Promise<Customer>;
  findPropertiesByName(name: string): Promise<Property[]>;
  getPropertiesForCustomer(opts: GetPropertiesOpts): Promise<Property[]>;
  createProperty(opts: CreatePropertyOpts): Promise<Property>;
  getPropertyById(id: string): Promise<Property>;
  getElevatorsForProperty(
    opts: GetElevatorsForPropertyOpts,
  ): Promise<Elevator[]>;
  getElevatorsForProperties(
    opts: GetElevatorsForPropertiesOpts,
  ): Promise<Elevator[]>;
  getElevatorById(id: string): Promise<Elevator>;
  saveElevator(opts: SaveElevatorOpts): Promise<Elevator>;
  updateElevator(opts: UpdateElevatorOpts): Promise<Elevator>;
  createContractOptions(opts: SaveContractOpts): Promise<string>;
  updateContract(id: string, selectedOption: ContractOptionType): Promise<void>;
  fetchFieldOptions(opts: GetOptionsForFieldOpts): Promise<string[]>;
  addFieldOption(opts: AddOptionForFieldOpts): Promise<void>;
  /** @deprecated in favor of addOpportunity */
  addRecommendationForElevator(
    opts: AddRecommendationForElevatorOpts,
  ): Promise<void>;
  addOpportunity(opts: AddOpportunityOpts): Promise<void>;
  getOpportunities(opts: GetOpportunitiesOpts): Promise<Opportunity[]>;
  updateOpportunity(opportunity: Opportunity): Promise<void>;
  addPhotoForElevator(opts: AddPhotoForElevatorOpts): Promise<string>;
  getContractEstimate(opts: ContractEstimateOpts): Promise<ContractEstimate>;
  sendEmail(opts: SendEmailOpts): Promise<{ ok: boolean }>;
  getSmartFilters(
    opts: GetSmartFiltersRequest,
  ): Promise<GetSmartFiltersResponse>;
  addMCPEntry(opts: AddMCPEntryOpts): Promise<MCPEntry>;
  getMCPEntries(): Promise<MCPEntry[]>;
  getMCPCategoriesWithTasks(
    surveyId: string,
  ): Promise<MCPCategoriesWithTasks[]>;
  getCopilotProperties(
    opts: GetCopilotPropertiesOpts,
  ): Promise<CopilotProperty[]>;
  getCopilotElevators(
    opts: GetCopilotElevatorsOpts,
  ): Promise<CopilotElevator[]>;
  querySalesGenieDb(
    query: QuerySalesGenieDbInput,
  ): Promise<QuerySalesGenieDbResponse>;

  /**
   * Fetches a list of users from the system
   * @returns Promise that resolves to an array of User objects
   */
  fetchUsers(): Promise<User[]>;

  /**
   * Imports workday data from an Excel file
   * @param opts The import options including the file and mechanic email
   * @returns Promise that resolves to the import results
   */
  importWorkday(opts: ImportWorkdayOpts): Promise<ImportWorkdayResult>;

  runScheduledFunctions(): Promise<void>;
}
