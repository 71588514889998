import type { API } from "./api";
import { IAPI } from "./interface";

export type { IAPI };

const apiPromise: Promise<API> = new Promise<API>((resolve) => {
  import("./api").then(({ API }) => {
    console.log("API loaded");
    const api = new API();

    api.syncWorker.on(
      "imageSync",
      async ({ fbUrl, id, parentId, fieldName }) => {
        if (!api) return;
        const elevator = await api.getElevatorById(parentId);
        if (!elevator) return;
        await api.saveElevator({
          elevator: {
            ...elevator,
            [fieldName]:
              elevator[fieldName] && Array.isArray(elevator[fieldName])
                ? elevator[fieldName]?.map((p) => {
                    if (typeof p !== "string" && p.id === id) {
                      return { url: fbUrl, id: null };
                    }
                    return p;
                  })
                : elevator[fieldName],
          },
        });
      },
    );

    resolve(api);
  });
});
export async function getAPI(): Promise<API> {
  return await apiPromise;
}
