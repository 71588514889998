import { useState, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Typeahead } from "../../components/Typeahead";
import { FileUpload } from "../../components/FileUpload";
import { useFieldOptions } from "../../fetchers/field-options";
import {
  guideOptions,
  motorControllerOptions,
  suspensionOptions,
  environmentOptions,
  environmentValueFormatter,
  yesNoOptions,
  conditionOptions,
  conditionValueFormatter,
  yearOptions as initialYearOptions,
  isTraction,
  isHydraulic,
  doorOperatorManufacturerOptions,
} from "../../models/elevator";
import { SystemTypeOption } from "../../api/interface";
import { RadioGroup } from "../../components/RadioGroup";
import { Select } from "../../components/Select";
import type { SurveyFormVm } from "./SurveyForm";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

const TechnicalTab = () => {
  const [yearOptions, setYearOptions] = useState<string[]>(initialYearOptions);

  const { control, setValue, watch } = useFormContext<SurveyFormVm>();

  const elevatorId = watch("id");

  const [typeOfUseQuery, setTypeOfUseQuery] = useState<string>("");
  const [isGeneralOpen, setIsGeneralOpen] = useState<boolean>(true);
  const [isHallwayOpen, setIsHallwayOpen] = useState<boolean>(true);
  const [isMachineRoomOpen, setIsMachineRoomOpen] = useState<boolean>(true);
  const [isCarOpen, setIsCarOpen] = useState<boolean>(true);
  const [isCarTopOpen, setIsCarTopOpen] = useState<boolean>(true);
  const [isPitOpen, setIsPitOpen] = useState<boolean>(true);
  const { options: typeOfUseOptions = [], addFieldOption: addUnitType } =
    useFieldOptions({
      query: typeOfUseQuery,
      fieldName: "typeOfUse",
      setValue,
    });

  const [doorRestrictorsQuery, setDoorRestrictorsQuery] = useState<string>("");
  const {
    options: doorRestrictorsOptions = [],
    addFieldOption: addDoorRestrictors,
  } = useFieldOptions({
    query: doorRestrictorsQuery,
    fieldName: "doorRestrictorsType",
    setValue,
  });

  const [sensorEdgeBrandAndModelQuery, setSensorEdgeBrandAndModelQuery] =
    useState<string>("");
  const {
    options: sensorAndModelOptions = [],
    addFieldOption: addSensorAndModel,
  } = useFieldOptions({
    query: sensorEdgeBrandAndModelQuery,
    fieldName: "sensorEdgeBrandAndModel",
    setValue,
  });

  const [doorOperatorModelQuery, setDoorOperatorModelQuery] =
    useState<string>("");
  const {
    options: doorOperatorModelOptions = [],
    addFieldOption: addDoorOperatorModel,
  } = useFieldOptions({
    query: doorOperatorModelQuery,
    fieldName: "doorOperatorModel",
    setValue,
  });

  const [governerTypeQuery, setGovernerTypeQuery] = useState<string>("");
  const { options: governorOptions = [], addFieldOption: addGovernor } =
    useFieldOptions({
      query: governerTypeQuery,
      fieldName: "governerType",
      setValue,
    });

  const [brakeTypeQuery, setBrakeTypeQuery] = useState<string>("");
  const { options: brakeTypeOptions = [], addFieldOption: addBrakeType } =
    useFieldOptions({
      query: brakeTypeQuery,
      fieldName: "brakeType",
      setValue,
    });

  const [
    controllerValveManufacturerQuery,
    setControllerValveManufacturerQuery,
  ] = useState<string>("");
  const { options: valveOptions = [], addFieldOption: addValve } =
    useFieldOptions({
      query: controllerValveManufacturerQuery,
      fieldName: "controllerValveManufacturer",
      setValue,
    });

  const [controllerValveModelQuery, setControllerValveModelQuery] =
    useState<string>("");
  const { options: valveModelOptions = [], addFieldOption: addValveModel } =
    useFieldOptions({
      query: controllerValveModelQuery,
      fieldName: "controllerValveModel",
      setValue,
    });

  const [bufferTypeQuery, setBufferTypeQuery] = useState<string>("");
  const { options: bufferOptions = [], addFieldOption: addBuffer } =
    useFieldOptions({
      query: bufferTypeQuery,
      fieldName: "bufferType",
      setValue,
    });

  const topOfCarPictures = watch("topOfCarPictures") || [];
  const ropeDataTagPicture = watch("ropeDataTagPicture") || [];
  const valvePictures = watch("valvePictures") || [];
  const pitPictures = watch("pitPictures") || [];
  const systemType = watch("systemType");

  const isUnitTraction = useMemo(
    () => isTraction(systemType as SystemTypeOption),
    [systemType],
  );
  const isUnitHydraulic = useMemo(
    () => isHydraulic(systemType as SystemTypeOption),
    [systemType],
  );

  const handleYearChange = (value: string) => {
    if (value) {
      setYearOptions(
        initialYearOptions.filter((option) => option.includes(value)),
      );
    } else {
      setYearOptions(initialYearOptions);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div
          onClick={() => setIsGeneralOpen(!isGeneralOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">General</span>
          {isGeneralOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isGeneralOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="typeOfUse"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Type of Use"
                  onQueryChange={setTypeOfUseQuery}
                  options={typeOfUseOptions}
                  onAdd={(value) => addUnitType(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="designQuality"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Quality of Design"
                  options={conditionOptions}
                  valueFormatter={conditionValueFormatter}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="environment"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Select
                  label="Environment"
                  options={environmentOptions}
                  valueFormatter={environmentValueFormatter}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsHallwayOpen(!isHallwayOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Hallway</span>
          {isHallwayOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isHallwayOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="doorEquipmentUpgradeNeeded"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Door Equipment Upgrade Needed?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="hallFixturesUpgradeNeeded"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Hall Fixtures Upgrade Needed?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="positionIndicatorUpgradeNeeded"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Position Indicator Upgrade Needed?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsMachineRoomOpen(!isMachineRoomOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Machine Room</span>
          {isMachineRoomOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isMachineRoomOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="motorControllerType"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Select
                  className="mb-4"
                  label="Type of Motor Controller"
                  options={motorControllerOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="brakeType"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Brake Type"
                  onQueryChange={setBrakeTypeQuery}
                  options={brakeTypeOptions}
                  onAdd={(value) => addBrakeType(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="driveSheaveLiners"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Drive Sheave Liners?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="governerType"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Governor Type"
                  onQueryChange={setGovernerTypeQuery}
                  options={governorOptions}
                  onAdd={(value) => addGovernor(value)}
                  {...fieldProps}
                />
              )}
            />
            {isUnitHydraulic && (
              <Controller
                control={control}
                name="pressureSwitchHydro"
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <RadioGroup
                    label="Does it have Low Pressure Switch (Hydro)?"
                    options={yesNoOptions}
                    {...fieldProps}
                  />
                )}
              />
            )}
            <Controller
              control={control}
              name="controllerValveManufacturer"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Valve Manufacturer"
                  onQueryChange={setControllerValveManufacturerQuery}
                  options={valveOptions}
                  onAdd={(value) => addValve(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="controllerValveModel"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Valve Model"
                  onQueryChange={setControllerValveModelQuery}
                  options={valveModelOptions}
                  onAdd={(value) => addValveModel(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="valvePictures"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Valve Pictures"
                  elevatorId={elevatorId}
                  currentImages={valvePictures}
                  maxFiles={5}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "valvePictures",
                        valvePictures.filter((u) => u !== url),
                      );
                    else setValue("valvePictures", [...valvePictures, ...url]);
                  }}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="batteryLowering"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Battery Lowering?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="DLM"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have DLM?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            {isUnitTraction && (
              <Controller
                control={control}
                name="biDirectionalGovernor"
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <RadioGroup
                    label="Does it have Bi-directional Governor?"
                    options={yesNoOptions}
                    {...fieldProps}
                  />
                )}
              />
            )}
            {isUnitHydraulic && (
              <>
                <Controller
                  control={control}
                  name="flexHoseHydro"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Flex Hose?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="pumpUnitUpgradeNeeded"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Pump Unit Upgrade Needed?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
              </>
            )}
            <Controller
              control={control}
              name="controllerUpgradeNeeded"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Controller Upgrade Needed?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            {isUnitTraction && (
              <Controller
                control={control}
                name="machineUpgradeNeeded"
                render={({ field: { ref: _, ...fieldProps } }) => (
                  <RadioGroup
                    label="Machine Upgrade Needed?"
                    options={yesNoOptions}
                    {...fieldProps}
                  />
                )}
              />
            )}
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsCarOpen(!isCarOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Car</span>
          {isCarOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isCarOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="sensorEdgeBrandAndModel"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Sensor Edge Type/Brand/Model"
                  onQueryChange={setSensorEdgeBrandAndModelQuery}
                  options={sensorAndModelOptions}
                  onAdd={(value) => addSensorAndModel(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="doorRestrictorsType"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Type of Door Restrictors"
                  onQueryChange={setDoorRestrictorsQuery}
                  options={doorRestrictorsOptions}
                  onAdd={(value) => addDoorRestrictors(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="doorOperatorManufacturer"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Select
                  label="Door Operator Manufacturer"
                  options={doorOperatorManufacturerOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="doorOperatorModel"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Door Operator Model"
                  onQueryChange={setDoorOperatorModelQuery}
                  options={doorOperatorModelOptions}
                  onAdd={(value) => addDoorOperatorModel(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="threeDSensorEdge"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have 3D Sensor Edge?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="cabUpgradeNeeded"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Cab Upgrade Needed?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsCarTopOpen(!isCarTopOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Car Top</span>
          {isCarTopOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isCarTopOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="guideType"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Select
                  className="mb-4"
                  label="Cab Guide Type"
                  options={guideOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="suspensionMeans"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Select
                  className="mb-4"
                  label="Suspension Means"
                  options={suspensionOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="suspensionMeansInstallYear"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  label="Suspension Means Install Year"
                  {...fieldProps}
                  options={yearOptions}
                  onQueryChange={handleYearChange}
                />
              )}
            />
            <Controller
              control={control}
              name="ropeDataTagPicture"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Rope Data Tag Picture"
                  elevatorId={elevatorId}
                  currentImages={ropeDataTagPicture}
                  maxFiles={1}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "ropeDataTagPicture",
                        ropeDataTagPicture.filter((u) => u !== url),
                      );
                    else
                      setValue("ropeDataTagPicture", [
                        ...ropeDataTagPicture,
                        ...url,
                      ]);
                  }}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="topOfCarPictures"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Top of Car Pictures"
                  helperText="Door Operator, Door Restrictor, Hoistway view from Car Top, Crosshead Data Tag"
                  elevatorId={elevatorId}
                  currentImages={topOfCarPictures}
                  maxFiles={10}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "topOfCarPictures",
                        topOfCarPictures.filter((u) => u !== url),
                      );
                    else
                      setValue("topOfCarPictures", [
                        ...topOfCarPictures,
                        ...url,
                      ]);
                  }}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="counterweightSafeties"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Counterweight Safeties?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="carTopHandrails"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Car Top Handrails?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
      <div>
        <div
          onClick={() => setIsPitOpen(!isPitOpen)}
          className="flex items-center justify-between cursor-pointer"
        >
          <span className="text-lg font-semibold">Pit</span>
          {isPitOpen ? (
            <ChevronUpIcon className="w-6" />
          ) : (
            <ChevronDownIcon className="w-6" />
          )}
        </div>
        {isPitOpen && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Controller
              control={control}
              name="compensationCablesChains"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Compensation Cables/Chains?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="bufferSwitches"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Buffer Switches?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="bufferType"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <Typeahead
                  allowAdd
                  label="Buffer Type"
                  onQueryChange={setBufferTypeQuery}
                  options={bufferOptions}
                  onAdd={(value) => addBuffer(value)}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="pitPictures"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <FileUpload
                  label="Pit Pictures"
                  helperText="Pit, Hoistway, Bottom of Car View"
                  elevatorId={elevatorId}
                  currentImages={pitPictures}
                  maxFiles={10}
                  handleOnChange={(url, imageDeleted) => {
                    if (imageDeleted)
                      setValue(
                        "pitPictures",
                        pitPictures.filter((u) => u !== url),
                      );
                    else setValue("pitPictures", [...pitPictures, ...url]);
                  }}
                  {...fieldProps}
                />
              )}
            />
            {isUnitHydraulic && (
              <>
                <Controller
                  control={control}
                  name="plungerGripperHydro"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Plunger Gripper?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="overspeedValveHydro"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Overspeed Valve?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="singleBottomBulkheadJack"
                  render={({ field: { ref: _, ...fieldProps } }) => (
                    <RadioGroup
                      label="Does it have Single Bottom Bulkhead Jack?"
                      options={yesNoOptions}
                      {...fieldProps}
                    />
                  )}
                />
              </>
            )}
            <Controller
              control={control}
              name="retractablePitLadder"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Retractable Pit Ladder?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="retractableToeGuard"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Retractable Toe Guard?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
            <Controller
              control={control}
              name="scavengerPump"
              render={({ field: { ref: _, ...fieldProps } }) => (
                <RadioGroup
                  label="Does it have Scavenger Pump?"
                  options={yesNoOptions}
                  {...fieldProps}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TechnicalTab;
