import classNames from "classnames";
import {
  Field,
  Radio,
  RadioGroup,
  Label as HeadlessLabel,
} from "@headlessui/react";

import { InputLabel } from "./InputLabel";
import { HelperText } from "./HelperText";

type Option =
  | {
      label: string;
      id: string | boolean;
      helperText?: string;
    }
  | string;

type RadioProps<T extends Option> = {
  name: string;
  label: string;
  onChange?: (value: T | null) => void;
  options: readonly Option[];
  className?: string;
  helperText?: string;
  value?: T | null;
};

export const RadioButtons = <T extends Option>({
  name,
  label,
  options,
  className,
  onChange,
  helperText,
  value,
}: RadioProps<T>) => {
  return (
    <div className={classNames(className)}>
      <InputLabel>{label}</InputLabel>
      <div className="flex flex-col align-middle">
        <RadioGroup
          className="flex flex-col gap-2 justify-between w-full"
          value={value}
          onChange={(value) => onChange?.(value)}
          aria-label={label}
          name={name}
        >
          {options.map((option) => {
            const optionLabel =
              typeof option === "string" ? option : option.label;
            const optionHelperText =
              typeof option === "string" ? null : option.helperText;
            const optionValue = typeof option === "string" ? option : option.id;
            return (
              <Field key={optionLabel} className="flex items-center gap-1">
                <Radio
                  className="group hover:cursor-pointer flex justify-center items-center mr-1 h-5 w-5 rounded-full border-gray-400 border-2"
                  value={optionValue}
                >
                  <div className="invisible h-2 w-2 rounded-full bg-main group-data-[checked]:visible"></div>
                </Radio>
                <HeadlessLabel className="hover:cursor-pointer flex flex-col">
                  {optionLabel}
                  {optionHelperText && (
                    <span className="text-sm text-gray-500">
                      {optionHelperText}
                    </span>
                  )}
                </HeadlessLabel>
              </Field>
            );
          })}
        </RadioGroup>
        <HelperText message={helperText || ""} />
      </div>
    </div>
  );
};
