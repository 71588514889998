import { ExploreElevator } from "src/models/elevator";
import { Filter } from "../../api/interface";

export type ElevatorKey = keyof ExploreElevator;
export const columnHeaderMap: Record<ElevatorKey, string> = {
  id: "Id",
  propertyId: "Property Id",
  servmanId: "Servman Id",
  customer: "Customer",
  propertyName: "Site Name",
  propertyAddress: "Site Address",
  name: "Name",
  unitNumber: "Unit Number",
  ahj: "AHJ",
  typeOfUse: "Type of Use",
  ahjId: "AHJ #",
  fireService: "Fire Service",
  facilityType: "Facility Type",
  traffic: "Traffic",
  doorType: "Door Type",
  condition: "Condition",
  designQuality: "Quality of Design",
  environment: "Environment",
  technology: "Technology",
  machineInstallYear: "Machine Install Year",
  controllerInstallYear: "Controller Install Year",
  landings: "Landings",
  frontLandings: "Front Landings",
  rearOrSideLandings: "Rear or Side Landings",
  site: "Site",
  sensorEdgeBrandAndModel: "Sensor Edge Brand and Model",
  motorControllerType: "Motor Controller Type",
  counterweightSafeties: "Counterweight Safeties",
  flexHoseHydro: "Flex Hose",
  governerType: "Governor Type",
  systemType: "System Type",
  pressureSwitchHydro: "Pressure Switch Hydro",
  driveSheaveLiners: "Drive Sheave Liners",
  batteryLowering: "Battery Lowering",
  brakeType: "Brake Type",
  controllerManufacturer: "Controller Manufacturer",
  controllerModel: "Controller Model",
  controllerValveManufacturer: "Controller Valve Manufacturer",
  controllerValveModel: "Controller Valve Model",
  suspensionMeans: "Suspension Means",
  suspensionMeansInstallYear: "Suspension Means Install Year",
  doorRestrictorsType: "Door Restrictors Type",
  guideType: "Guide Type",
  doorOperatorManufacturer: "Door Operator Manufacturer",
  doorOperatorModel: "Door Operator Model",
  hoistwayAccess: "Hoistway Access",
  bufferType: "Buffer Type",
  bufferSwitches: "Buffer Switches",
  compensationCablesChains: "Compensation Cables Chains",
  overspeedValveHydro: "Overspeed Valve",
  plungerGripperHydro: "Plunger Gripper",
  notes: "Notes",
  certificatePicture: "Certificate Picture",
  fixturePictures: "Fixture Pictures",
  machineRoomPictures: "Machine Room Pictures",
  controllerPicture: "Controller Picture",
  additionalPhotos: "Additional Photos",
  topOfCarPictures: "Top of Car Pictures",
  ropeDataTagPicture: "Rope Data Tag Picture",
  valvePictures: "Valve Pictures",
  pitPictures: "Pit Pictures",
  isThereAMachineRoom: "Machine Room",
  region: "Branch",
  contractType: "Contract Type",
  contractId: "Contract Id",
  enforcerDoorGibs: "Does it have Enforcer Door Gibs?",
  LEDDownlightCeiling: "Does it have LED Downlight Ceiling?",
  dateOfLastCAT1Testing: "Date of Last CAT-1 Testing",
  dateOfLastCAT5Testing: "Date of Last CAT-5 Testing",
  softStartElectronicStarter: "Does it have Soft Start (Electronic Starter)?",
  tankCooler: "Does it have Tank Cooler?",
  tankHeater: "Does it have Tank Heater?",
  ropeGripper: "Does it have Rope Gripper?",
  doorEquipmentUpgradeNeeded: "Door Equipment Upgrade Needed",
  hallFixturesUpgradeNeeded: "Hall Fixtures Upgrade Needed",
  positionIndicatorUpgradeNeeded: "Position Indicator Upgrade Needed",
  DLM: "Does it have DLM?",
  biDirectionalGovernor: "Does it have Bi-Directional Governor?",
  pumpUnitUpgradeNeeded: "Pump Unit Upgrade Needed",
  controllerUpgradeNeeded: "Controller Upgrade Needed",
  machineUpgradeNeeded: "Machine Upgrade Needed",
  threeDSensorEdge: "Does it have 3D Sensor Edge?",
  cabUpgradeNeeded: "Cab Upgrade Needed",
  carTopHandrails: "Does it have Car Top Handrails?",
  singleBottomBulkheadJack: "Does it have Single Bottom Bulkhead Jack?",
  retractablePitLadder: "Does it have Retractable Pit Ladder?",
  retractableToeGuard: "Does it have Retractable Toe Guard?",
  scavengerPump: "Does it have Scavenger Pump?",
  capacityLbs: "Capacity (LBS)",
  speedFpm: "Speed (FPM)",
};
export const noShowColumnsMap = new Set<ElevatorKey>([
  "id",
  "propertyId",
  "servmanId",
  "contractId",
  "propertyName",
]);

export const numberColumnsMap = new Set<ElevatorKey>([
  "landings",
  "frontLandings",
  "rearOrSideLandings",
  "capacityLbs",
  "speedFpm",
]);

export const pictureColumnsMap = new Set<ElevatorKey>([
  "certificatePicture",
  "fixturePictures",
  "machineRoomPictures",
  "controllerPicture",
  "additionalPhotos",
  "topOfCarPictures",
  "ropeDataTagPicture",
  "valvePictures",
  "pitPictures",
]);

export const nonFilterableColumns = new Set<string>([
  ...pictureColumnsMap,
  ...noShowColumnsMap,
]);

const setToArrayOrNull = (choices: Set<string>): string[] | null => {
  return choices.size ? Array.from(choices) : null;
};

export const getSmartFiltersMetadataForElevators = (
  elevators: ExploreElevator[],
): Filter[] => {
  const choicesMap = Object.keys(columnHeaderMap).reduce(
    (acc, key) => {
      acc[key as ElevatorKey] = new Set<string>();
      return acc;
    },
    {} as Record<ElevatorKey, Set<string>>,
  );

  for (const elevator of elevators) {
    for (const [key, value] of Object.entries(elevator)) {
      if (
        !nonFilterableColumns.has(key) &&
        (typeof value === "string" || typeof value === "boolean")
      ) {
        choicesMap[key as ElevatorKey].add(value.toString());
      }
    }
  }

  return [
    {
      type: "single-select",
      name: "name",
      description: "The name of the elevator",
      choices: setToArrayOrNull(choicesMap.name),
    },
    {
      type: "single-select",
      name: "unitNumber",
      description: "The unique number assigned to the elevator unit",
      choices: setToArrayOrNull(choicesMap.unitNumber),
    },
    {
      type: "single-select",
      name: "ahj",
      description: "The Authority Having Jurisdiction over the elevator",
      choices: setToArrayOrNull(choicesMap.ahj),
    },
    {
      type: "single-select",
      name: "typeOfUse",
      description: "The primary purpose or usage category of the elevator",
      choices: setToArrayOrNull(choicesMap.typeOfUse),
    },
    {
      type: "single-select",
      name: "ahjId",
      description:
        "The identification number assigned by the Authority Having Jurisdiction",
      choices: setToArrayOrNull(choicesMap.ahjId),
    },
    {
      type: "single-select",
      name: "fireService",
      description: "The fire service capabilities of the elevator",
      choices: setToArrayOrNull(choicesMap.fireService),
    },
    {
      type: "single-select",
      name: "facilityType",
      description: "The type of facility where the elevator is installed",
      choices: setToArrayOrNull(choicesMap.facilityType),
    },
    {
      type: "single-select",
      name: "traffic",
      description: "The typical traffic load or usage pattern of the elevator",
      choices: setToArrayOrNull(choicesMap.traffic),
    },
    {
      type: "single-select",
      name: "doorType",
      description: "The type of doors installed on the elevator",
      choices: setToArrayOrNull(choicesMap.doorType),
    },
    {
      type: "single-select",
      name: "condition",
      description: "The overall condition or state of the elevator",
      choices: setToArrayOrNull(choicesMap.condition),
    },
    {
      type: "single-select",
      name: "designQuality",
      description: "The quality of the elevator's design and construction",
      choices: setToArrayOrNull(choicesMap.designQuality),
    },
    {
      type: "single-select",
      name: "environment",
      description: "The environmental conditions surrounding the elevator",
      choices: setToArrayOrNull(choicesMap.environment),
    },
    {
      type: "single-select",
      name: "technology",
      description: "The technological features or systems of the elevator",
      choices: setToArrayOrNull(choicesMap.technology),
    },
    {
      type: "number",
      name: "machineInstallYear",
      description: "The year the elevator machine was installed",
    },
    {
      type: "number",
      name: "controllerInstallYear",
      description: "The year the elevator controller was installed",
    },
    {
      type: "number",
      name: "landings",
      description: "The total number of landings served by the elevator",
    },
    {
      type: "number",
      name: "frontLandings",
      description: "The number of front-opening landings",
    },
    {
      type: "number",
      name: "rearOrSideLandings",
      description: "The number of rear or side-opening landings",
    },
    {
      type: "single-select",
      name: "site",
      description: "The name of of the elevators property",
      choices: setToArrayOrNull(choicesMap.site),
    },
    {
      type: "single-select",
      name: "sensorEdgeBrandAndModel",
      description: "The brand and model of the elevator's sensor edge",
      choices: setToArrayOrNull(choicesMap.sensorEdgeBrandAndModel),
    },
    {
      type: "single-select",
      name: "motorControllerType",
      description: "The type of motor controller used in the elevator",
      choices: setToArrayOrNull(choicesMap.motorControllerType),
    },
    {
      type: "single-select",
      name: "counterweightSafeties",
      description: "Indicates if the elevator has counterweight safeties",
      choices: setToArrayOrNull(choicesMap.counterweightSafeties),
    },
    {
      type: "single-select",
      name: "flexHoseHydro",
      description: "Information about the elevator's flexible hydraulic hose",
      choices: setToArrayOrNull(choicesMap.flexHoseHydro),
    },
    {
      type: "single-select",
      name: "governerType",
      description: "The type of governor used in the elevator system",
      choices: setToArrayOrNull(choicesMap.governerType),
    },
    {
      type: "single-select",
      name: "systemType",
      description: "The overall type or category of the elevator system",
      choices: setToArrayOrNull(choicesMap.systemType),
    },
    {
      type: "single-select",
      name: "pressureSwitchHydro",
      description: "Information about the hydraulic pressure switch",
      choices: setToArrayOrNull(choicesMap.pressureSwitchHydro),
    },
    {
      type: "single-select",
      name: "driveSheaveLiners",
      description: "The type or condition of drive sheave liners",
      choices: setToArrayOrNull(choicesMap.driveSheaveLiners),
    },
    {
      type: "single-select",
      name: "batteryLowering",
      description: "Information about the battery-powered lowering system",
      choices: setToArrayOrNull(choicesMap.batteryLowering),
    },
    {
      type: "single-select",
      name: "brakeType",
      description: "The type of brake system used in the elevator",
      choices: setToArrayOrNull(choicesMap.brakeType),
    },
    {
      type: "single-select",
      name: "controllerManufacturer",
      description: "The manufacturer of the elevator controller",
      choices: setToArrayOrNull(choicesMap.controllerManufacturer),
    },
    {
      type: "single-select",
      name: "controllerModel",
      description: "The model of the elevator controller",
      choices: setToArrayOrNull(choicesMap.controllerModel),
    },
    {
      type: "single-select",
      name: "controllerValveManufacturer",
      description: "The manufacturer of the controller valve",
      choices: setToArrayOrNull(choicesMap.controllerValveManufacturer),
    },
    {
      type: "single-select",
      name: "controllerValveModel",
      description: "The model of the controller valve",
      choices: setToArrayOrNull(choicesMap.controllerValveModel),
    },
    {
      type: "single-select",
      name: "suspensionMeans",
      description: "The method or system used for elevator suspension",
      choices: setToArrayOrNull(choicesMap.suspensionMeans),
    },
    {
      type: "number",
      name: "suspensionMeansInstallYear",
      description: "The year the suspension means was installed",
    },
    {
      type: "single-select",
      name: "doorRestrictorsType",
      description: "The type of door restrictors used in the elevator",
      choices: setToArrayOrNull(choicesMap.doorRestrictorsType),
    },
    {
      type: "single-select",
      name: "guideType",
      description: "The type of guides used in the elevator system",
      choices: setToArrayOrNull(choicesMap.guideType),
    },
    {
      type: "single-select",
      name: "doorOperatorManufacturer",
      description: "The manufacturer of the door operator",
      choices: setToArrayOrNull(choicesMap.doorOperatorManufacturer),
    },
    {
      type: "single-select",
      name: "doorOperatorModel",
      description: "The model of the door operator",
      choices: setToArrayOrNull(choicesMap.doorOperatorModel),
    },
    {
      type: "single-select",
      name: "hoistwayAccess",
      description: "Information about access to the elevator hoistway",
      choices: setToArrayOrNull(choicesMap.hoistwayAccess),
    },
    {
      type: "single-select",
      name: "bufferType",
      description: "The type of buffers used in the elevator system",
      choices: setToArrayOrNull(choicesMap.bufferType),
    },
    {
      type: "single-select",
      name: "bufferSwitches",
      description: "Information about the elevator's buffer switches",
      choices: setToArrayOrNull(choicesMap.bufferSwitches),
    },
    {
      type: "single-select",
      name: "compensationCablesChains",
      description: "Details about compensation cables or chains",
      choices: setToArrayOrNull(choicesMap.compensationCablesChains),
    },
    {
      type: "single-select",
      name: "overspeedValveHydro",
      description: "Information about the hydraulic overspeed valve",
      choices: setToArrayOrNull(choicesMap.overspeedValveHydro),
    },
    {
      type: "single-select",
      name: "plungerGripperHydro",
      description: "Details about the hydraulic plunger gripper",
      choices: setToArrayOrNull(choicesMap.plungerGripperHydro),
    },
    {
      type: "single-select",
      name: "notes",
      description: "Additional notes or comments about the elevator",
      choices: setToArrayOrNull(choicesMap.notes),
    },
    {
      type: "single-select",
      name: "isThereAMachineRoom",
      description: "Indicates if the elevator has a dedicated machine room",
      choices: setToArrayOrNull(choicesMap.isThereAMachineRoom),
    },
    {
      type: "single-select",
      name: "region",
      description: "The region where the elevator is located",
      choices: setToArrayOrNull(choicesMap.region),
    },
    {
      type: "single-select",
      name: "contractType",
      description: "The type of contract the elevator has",
      choices: setToArrayOrNull(choicesMap.contractType),
    },
  ];
};

export const booleanColumnsMap = new Set<string>([
  "counterweightSafeties",
  "flexHoseHydro",
  "pressureSwitchHydro",
  "driveSheaveLiners",
  "hoistwayAccess",
  "bufferSwitches",
  "compensationCablesChains",
  "overspeedValveHydro",
  "plungerGripperHydro",
  "isThereAMachineRoom",
]);

export const yearColumnsMap = new Set<string>([
  "machineInstallYear",
  "controllerInstallYear",
  "suspensionMeansInstallYear",
]);

export const dateColumnsMap = new Set<string>([
  "dateOfLastCAT1Testing",
  "dateOfLastCAT5Testing",
]);
