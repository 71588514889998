import useSWR, { mutate } from "swr";

import { ElevatorInput } from "../models/elevator";
import { getAPI } from "../api";
import { UpdateElevatorOpts } from "../api/interface";

const elevatorsKey = "elevators";

const elevatorsFetcher = async ([_, propertyId]: [
  key: string,
  propertyId: string,
]) => {
  const api = await getAPI();
  return api.getElevatorsForProperty({
    propertyId,
  });
};

const elevatorFetcher = async ([_, elevatorId]: [
  key: string,
  elevatorId: string,
]) => {
  const api = await getAPI();
  return api.getElevatorById(elevatorId);
};

const multiPropertiesElevatorsFetcher = async ([_, propertyIds]: [
  key: string,
  propertyIds: string[],
]) => {
  const api = await getAPI();
  return api.getElevatorsForProperties({ propertyIds });
};

export const saveElevator = async (elevator: ElevatorInput) => {
  const api = await getAPI();

  const updatedElevator = await api.saveElevator({
    elevator,
  });

  // This triggers a revalidations of elevators
  mutate(
    // TODO: only revalidate the specific elevator or elevators for the property
    (key) => {
      return Array.isArray(key) && key[0] === elevatorsKey;
    },
    undefined,
    {
      revalidate: true,
    },
  );

  return updatedElevator;
};

export const updateElevator = async (opts: UpdateElevatorOpts) => {
  const api = await getAPI();
  return api.updateElevator(opts);
};

export const useElevators = (propertyId: string | null) => {
  const { data: elevators } = useSWR(
    propertyId ? [elevatorsKey, propertyId] : null,
    elevatorsFetcher,
  );

  return {
    elevators,
    saveElevator,
  };
};

export const useMultiPropertiesElevators = (propertyIds: string[]) => {
  const { data: elevators, isLoading } = useSWR(
    [elevatorsKey, propertyIds],
    multiPropertiesElevatorsFetcher,
  );

  return {
    elevators,
    isLoading,
  };
};

export const useElevator = (elevatorId: string | null) => {
  const { data: elevator, isLoading } = useSWR(
    elevatorId ? [elevatorsKey, elevatorId] : null,
    elevatorFetcher,
  );

  return {
    isLoading,
    elevator,
  };
};
